import { SHOP_ORIGIN } from '@/services/Configuration'

const getUrlFromMaybUrlMaybePath = (maybeUrlMaybePath: string) => (
  maybeUrlMaybePath.startsWith('http')
    ? maybeUrlMaybePath
    : `${SHOP_ORIGIN}${maybeUrlMaybePath}`
)

export const addParamsToUrl = (
  maybeUrlMaybePath: string, params: Record<string, string>,
) => {
  const urlStr = getUrlFromMaybUrlMaybePath(maybeUrlMaybePath)
  const urlObj = new URL(urlStr)
  Object.entries(params).forEach(([key, value]) => {
    urlObj.searchParams.set(key, value)
  })
  return urlObj.toString()
}
